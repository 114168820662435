<script setup lang="ts">
import type { File } from '~/types/api'

const props = defineProps<{
  images: File[]
}>()

const { visible, index, showImg } = useLightbox()
</script>

<template>
  <UiCarousel
    :opts="{
      align: 'center',
      loop: false,
      skipSnaps: true,
      dragFree: true,
    }"
    orientation="horizontal"
    class="w-full gap-4"
  >
    <UiCarouselPrevious
      class="group absolute left-2 top-1/2 z-10 mx-auto flex -translate-y-1/2 translate-x-0"
    >
      <Icon
        class="control-arrow pointer-events-auto mx-2.5 cursor-pointer text-sm text-white !outline-0"
        name="bi:arrow-left"
        size="30"
      />
    </UiCarouselPrevious>
    <UiCarouselContent class="gap-4">
      <UiCarouselItem
        class="data-gallery group flex basis-auto flex-col items-center justify-center"
        v-for="(image, index) in props.images"
        :key="index"
      >
        <UiGalleryThumbnail :image="image" @show-image="showImg(index)" />
      </UiCarouselItem>
    </UiCarouselContent>
    <UiCarouselNext
      class="group absolute right-2 top-1/2 z-10 mx-auto flex -translate-y-1/2 translate-x-0"
    >
      <Icon
        class="control-arrow pointer-events-auto mx-2.5 cursor-pointer text-sm text-white !outline-0"
        name="bi:arrow-right"
        size="30"
      />
    </UiCarouselNext>
  </UiCarousel>
  <UiLightbox
    v-model="visible"
    :index="index"
    :images="
      props.images.map((image) => withHost(image.url + '/2000x2000/FIT'))
    "
  />
</template>

<style>
.gallery-control [aria-disabled='true'] {
  visibility: hidden;
}
</style>
