<script setup lang="ts">
import type { FileDetail } from '~/types/api'

const props = defineProps<{
  image: FileDetail
  containImage?: boolean
}>()

const { getResourceUrl, getImageSrcSet } = useResources()
const srcset = getImageSrcSet(props.image.url)

const emit = defineEmits(['showImage'])
</script>

<template>
  <div>
    <div
      @click="() => emit('showImage')"
      class="gallery-carousel-slide relative inline-block h-64 w-40 bg-white after:absolute after:left-0 after:top-0 after:box-border after:block after:h-full after:w-full after:bg-[50%] after:bg-no-repeat after:opacity-0 after:transition-opacity after:content-[''] group-hover:after:opacity-100"
      :class="{
        'w-40': image.imageRatioString === 'portrait',
        'w-64': image.imageRatioString === 'square',
        'w-96': image.imageRatioString === 'landscape',
      }"
    >
      <img
        class="lazy-img left-0 top-0 block h-full w-full object-cover group-hover:opacity-40"
        :class="{
          '!object-contain': containImage,
        }"
        :srcset="srcset"
        :src="getResourceUrl(image.url + '/576x576/FIT')"
        :alt="image.metaAlt ?? 'Gallery image'"
        :title="image.metaTitle ?? 'Gallery image'"
        loading="lazy"
      />
    </div>
  </div>
</template>

<style>
.gallery-carousel-slide:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' d='M60 32.5H4m56 0H4m56 0H4M31.5 60V4m0 56V4m0 56V4'/%3E%3C/svg%3E");
  background-size: 64px 64px;
}
</style>
